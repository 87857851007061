import Image from "next/image";

import splitImg from "../../public/images/split/split-8.png";
import Link from "next/link";
import bannerImg from "../../public/videos/robotgif.gif";

const Split = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap" style={{background:'#000'}}>
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-5 col-12">
                  <div className="thumbnail">
                    <Image
                      className="radius"
                      src={bannerImg}
                      alt="split Images"
                    // width={500}
                    // height={500}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-7 col-12">
                  <div className="split-inner">
                    <h4
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      About APTRON
                    </h4>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      A state-of-the-art AI framework,
                      APTRON, pioneers the creation of AI-driven technologies tailored for the domains of Web3, Blockchain, and Crypto. Our objective is to enhance the Web3 experience for retail users and startups through the development of AI-powered solutions specifically crafted for the Web3 ecosystem. Whether it's Language Models (LLMs) or cutting-edge Web3 AI Tools, APTRON serves as the ultimate destination to elevate your Web3 journey with the transformative capabilities of Artificial Intelligence.
                    </p>
                    <div className="d-flex" style={{ gap: '20px', justifyContent: 'space-between' }} >
                      <ul className="split-list">
                        {Technlogies.slice(0, 3).map((item, i) =>
                          <li key={i}>- {item}</li>
                        )}
                      </ul>
                      <ul className="split-list" >
                        {Technlogies.slice(3, 6).map((item, i) =>
                          <li key={i}>- {item}</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Split;

const Technlogies = [
  "Privacy and Security",
  "Cost and Complexity",
  "Immutable Data",
  "Regulatory Compliance",
  "Interoperability",
  "Energy Consumption",
]
import React, { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import sal from "sal.js";
import ToolData from "../../data/header.json";
import { useAccount } from 'wagmi'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { Tooltip } from "react-tooltip";

const CallToAction = () => {
  const router = useRouter()
  const { open } = useWeb3Modal()
  const { isConnected, } = useAccount()

  useEffect(() => {
    sal();
  }, []);

  const [isManual, setIsManual] = React.useState(false)
  const connect = async () => {
    setIsManual(true)
    open()
  }
  const navigate = () => {
    setTimeout(() => {
      router.push("/ai-chatbot");
    }, 1000);
  };
  // console.log(rest, "rest")
  React.useEffect(() => {
    if (isManual && isConnected) {
      navigate()
    }
  }, [isConnected, isManual])

  return (
    <>
      <Tooltip id="my-tooltip" className="custom-tooltip tooltip-inner" />
      <div className="rainbow-callto-action-area">
        <div className="wrapper">
          <div className="rainbow-callto-action clltoaction-style-default rainbow-section-gap">
            <div className="container">
              <div className="row row--0">
                <div className="col-lg-12">
                  <div className="align-items-center content-wrapper">
                    <div className="inner">
                      <div className="content text-center">
                        <span className="theme-gradient b2 mb--30 d-inline-block">
                          Boost your productivity with AI
                        </span>
                        <h2
                          className="title"
                          data-sal="slide-up"
                          data-sal-duration="400"
                          data-sal-delay="200"
                        >
                          Expereince the new world of AI tools
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="fancy-genearate-section">
            <div className="container">
              <div className="genarator-section">
                <ul className="genarator-card-group full-width-list">
                  {
                    Technlogies.slice(0, 5).map((data, index) => (
                      <li key={index} className={`${data.isDisable && "disabled"}`}  >
                        <Link
                          href={isConnected ? data.link : "/"}
                          className="genarator-card bg-flashlight-static center-align"
                        >
                          <div className="inner">
                            <div className="left-align">
                              <div className="img-bar">
                                <Image
                                  src={data.img}
                                  width={data.wdt ? data.wdt : 40}
                                  height={40}
                                  alt="AI Generator"
                                />
                              </div>
                              <h5 className="title">{data.title}</h5>
                              {isConnected ?
                                <span
                                  className="rainbow-demo-btn">
                                  Try It Now Free
                                </span>
                                : <span
                                  className="rainbow-demo-btn"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Connect wallet before proceeding.">
                                  Try It Now Free
                                </span>
                              }
                            </div>
                          </div>
                          {data.badge !== "" ? (
                            <span className="rainbow-badge-card">
                              {data.badge}
                            </span>
                          ) : (
                            ""
                          )}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="fancy-genearate-section">
            <div className="container">
              <div className="genarator-section">
                <ul className="genarator-card-group full-width-list">
                  {
                    Technlogies.slice(5, 10).map((data, index) => (
                      <li key={index} className={`${data.isDisable && "disabled"}`}>
                        <Link
                          href={isConnected ? data.link : "/"}
                          className="genarator-card bg-flashlight-static center-align"
                        >
                          <div className="inner">
                            <div className="left-align">
                              <div className="img-bar">
                                <Image
                                  src={data.img}
                                  width={data.wdt ? data.wdt : 40}
                                  height={40}
                                  alt="AI Generator"
                                />
                              </div>
                              <h5 className="title">{data.title}</h5>
                              {isConnected ?
                                <span
                                  className="rainbow-demo-btn">
                                  Try It Now Free
                                </span>
                                : <span
                                  className="rainbow-demo-btn"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Connect wallet before proceeding.">
                                  Try It Now Free
                                </span>
                              }
                            </div>
                          </div>
                          {data.badge !== "" ? (
                            <span className="rainbow-badge-card">
                              {data.badge}
                            </span>
                          ) : (
                            ""
                          )}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallToAction;


const Technlogies = [
  {
    "link": "/document-analyser",
    "isDisable": true,
    "title": "Document Analyser",
    "img": "/images/generator-icon/website-design_line.png",
    "imgSideBard": "",
    "badge": "Coming",
    "wdt": 36
  },
  {
    "link": "/p2p-messaging",
    "isDisable": false,
    "title": "P2P Messaging",
    "img": "/images/generator-icon/email_line.png",
    "imgSideBard": "",
    "badge": "Live",
    "wdt": 36
  },
  {
    "link": "/ai-chatbot",
    "isDisable": false,
    "title": "AI Chatbot",
    "img": "/images/generator-icon/text_line.png",
    "imgSideBard": "",
    "badge": "Live",
    "wdt": 44
  },
  {
    "link": "/image-generator",
    "isDisable": false,
    "title": "Image Generator",
    "img": "/images/generator-icon/photo-editor_line.png",
    "imgSideBard": "",
    "badge": "Live",
    "wdt": 36
  },

  {
    "link": "/",
    "isDisable": true,
    "title": "LaunchPad",
    "img": "/images/generator-icon/website-design_line.png",
    "imgSideBard": "",
    "badge": "Coming",
    "wdt": 44
  },
  {
    "link": "/",
    "isDisable": true,
    "title": "Blockchain Analytics",
    "img": "/images/generator-icon/website-design_line.png",
    "imgSideBard": "",
    "badge": "Coming",
    "wdt": 36
  },
  {
    "link": "/",
    "isDisable": true,
    "title": "Smart Conract Auditor",
    "img": "/images/generator-icon/document_line.png",
    "imgSideBard": "",
    "badge": "Coming",
    "wdt": 36
  },
  {
    "link": "/",
    "isDisable": true,
    "title": "Code Generator",
    "img": "/images/generator-icon/code-editor_line.png",
    "imgSideBard": "",
    "badge": "Coming",
    "wdt": 36
  },
  {
    "link": "/",
    "isDisable": true,
    "title": "Smart Conract Generator",
    "img": "/images/generator-icon/document_line.png",
    "imgSideBard": "",
    "badge": "Coming",
    "wdt": 36
  },
  {
    "link": "/",
    "isDisable": true,
    "title": "NFT Generator",
    "img": "/images/generator-icon/photo_line.png",
    "imgSideBard": "",
    "badge": "Coming",
    "wdt": 36
  },
]

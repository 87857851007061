import React, { useEffect } from "react";
import sal from "sal.js";
import "venobox/dist/venobox.min.css";
import Slider from "react-slick";
import Link from "next/link";

const Timeline = () => {
  useEffect(() => {
    sal();

    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
      });
    });
  }, []);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <>
      <div className="rainbow-timeline-area rainbow-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h4 className="subtitle ">
                  <span className="theme-gradient">HOW IT WORKS</span>
                </h4>
                <h2 className="title w-600 mb--20">
                  Guide Our AI to Create Your Copy
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 offset-lg-1 mt--30">
              <div className="timeline-style-two bg-flashlight bg-color-blackest">
                <Slider {...settings}>
                  <div>
                    <div className="row row--0">
                      {
                        Stage1.map((data, index) => {
                          const cssCol = `col-lg-${Stage1.length - 1} col-md-${Stage1.length - 1}`
                          return (
                            <div
                              className={`${cssCol} rainbow-timeline-single dark-line`}
                              key={index}
                            >
                              <div className="rainbow-timeline">
                                <h6
                                  className="title"
                                  data-sal="slide-up"
                                  data-sal-duration="700"
                                  data-sal-delay="200"
                                >
                                  {data.title}
                                </h6>
                                <div className="progress-line">
                                  <div className="line-inner"></div>
                                </div>
                                <div className="progress-dot">
                                  <div className="dot-level">
                                    <div className="dot-inner"></div>
                                  </div>
                                </div>
                                <ul>
                                  <p
                                    className="description"
                                    data-sal="slide-up"
                                    data-sal-duration="700"
                                    data-sal-delay="300"
                                    style={{ textAlign: 'left', }}
                                  >
                                    {data.tasks.map((item) =>
                                      <li key={item} style={{ letterSpacing: '0.1px', lineHeight: '20px', fontSize: '14px' }}>
                                        {item}
                                      </li>
                                    )}
                                  </p>
                                </ul>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                  <div>
                    <div className="row row--0">
                      {
                        Stage2.map((data, index) => {
                          const cssCol = `col-lg-${Stage1.length - 1} col-md-${Stage1.length - 1}`
                          return (
                            <div
                              className={`${cssCol} rainbow-timeline-single dark-line`}
                              key={index}
                            >
                              <div className="rainbow-timeline">
                                <h6
                                  className="title"
                                  data-sal="slide-up"
                                  data-sal-duration="700"
                                  data-sal-delay="200"
                                >
                                  {data.title}
                                </h6>
                                <div className="progress-line">
                                  <div className="line-inner"></div>
                                </div>
                                <div className="progress-dot">
                                  <div className="dot-level">
                                    <div className="dot-inner"></div>
                                  </div>
                                </div>
                                <ul>
                                  <p
                                    className="description"
                                    data-sal="slide-up"
                                    data-sal-duration="700"
                                    data-sal-delay="300"
                                    style={{ textAlign: 'left', }}
                                  >
                                    {data.tasks.map((item) =>
                                      <li key={item} style={{ letterSpacing: '0.1px', lineHeight: '20px', fontSize: '14px' }}>
                                        {item}
                                      </li>
                                    )}
                                  </p>
                                </ul>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </Slider>
              </div>
              {/* <div className="button-group mt--50 text-center">
                <Link className="btn-default btn-large" href="/ai-chatbot">
                  Start writing for free
                </Link>
                <Link
                  className="btn-default btn-large btn-border popup-video"
                  href="https://youtu.be/ThRYF96HIzA?si=Yz-Yc5HSf8uLPv-G"
                  data-vbtype="video"
                >
                  <span>
                    <i className="feather-play"></i>
                  </span>{" "}
                  See action in video
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timeline;


const Stage1 = [
  {
    title: "Q1 - 2024",
    tasks: [
      "Concept Refinement and Legal Preparation",
      "Market Research and Tokenomics Design",
      "Fundraising & IDO",
      "Token Generation Event (TGE)"
    ]
  },
  {
    title: "Q2 - 2024",
    tasks: [
      "Prototype Development",
      "Partnerships and Team Expansion",
      "$ATN AI bot Architecture"
    ]
  },
  {
    title: "Q3 - 2024",
    tasks: [
      "Feature Integration and Expansion",
      "Chatbot Feature Development",
      "Develop and integrate NFT generation tool capabilities into the $ATN AI bot"
    ]
  },
  {
    title: "Q4 - 2024",
    tasks: [
      "Code Generator and Smart Contract tool Development",
      "Advanced Functionalities and Security",
      "Integrate a smart contract generator tool into the $ATN AI bot"
    ]
  }
]

const Stage2 = [
  {
    title: "Q1 - 2025",
    tasks: [
      "Code Auditor tool development",
      "Beta Testing and Iterative Improvements",
      "Conduct a comprehensive security audit of code and smart contract modules"
    ]
  },
  {
    title: "Q2 - 2025",
    tasks: [
      "Develop and integrate an AI trading assistant, incorporating risk management protocols",
      "Beta Testing and Iterative Improvements",
      "Finalization and User Testing"
    ]
  },
  {
    title: "Q3 - 2025",
    tasks: [
      "Messaging Bot Development",
      "Integrate messaging bot functionalities, ensuring seamless communication",
      "Release a beta version to a select group for feedback"
    ]
  },
  {
    title: "Q4 - 2025",
    tasks: [
      "Launch and Post-Launch Activities",
      "Optimize performance, fix any remaining issues, and prepare for the official launch",
      "Official Launch and Continuous Improvement"
    ]
  }
]
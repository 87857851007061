import Header from "@/components/Header/Header";
// import HeaderTop from "@/components/Header/HeaderTop/Header-Top";
import PopupMobileMenu from "@/components/Header/PopupMobileMenu";
import Home from "@/components/Home/Home";
import Service from "@/components/Service/Service";
import Context from "@/context/Context";
import Separator from "../separator";
import Timeline from "@/components/Timeline/Timeline";
import Split from "@/components/Split/Split";
import Accordion from "@/components/Accordion/Accordion";
// import Pricing from "@/components/Pricing/Pricing";
// import Brands from "@/components/Brands/Brands";
import CallToAction from "@/components/CallToAction/CallToAction";
import Footer from "@/components/Footer/Footer";
import Copyright from "@/components/Footer/Copyright";
import PageHead from "../Head";
import Mission from "@/components/Mission/Mission";
import Teams from "@/components/Teams/Teams";

const HomePage = () => {
  return (
    <>
      <PageHead title="Home" />

      <main className="page-wrapper">
        <Context>
          {/* <HeaderTop /> */}
          <Header
            headerTransparent="header-not-transparent"
            headerSticky="header-sticky"
            btnClass="btn-small round"
          />
          <PopupMobileMenu />

          <Home />
          {/* <Separator top={true} /> */}
          <Split />
          {/* <Separator top={true} /> */}
          <CallToAction />
          <footer className="rainbow-footer footer-style-default footer-style-3 position-relative">
            <Separator top={true} />
            <div className="footer-top">
            </div>
          </footer>
          <Service />
          <Separator top={false} />
          <Timeline />
          <Separator top={true} />
          <Mission />
          {/* <Pricing /> */}
          <Separator top={true} />
          {/* <Teams /> */}
          <Separator top={false} />
          <Accordion isHead={true} />
          {/* <Brands /> */}
          {/* <Separator top={true} /> */}
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default HomePage;

import React, { useEffect } from "react";

import sal from "sal.js";
import MissionItem from "./MissionItem";

// import ServiceItem from "./ServiceItem";
import MissonData from "../../data/home.json";

const Mission = () => {
    useEffect(() => {
        sal();

        const cards = document.querySelectorAll(".bg-flashlight");

        cards.forEach((bgflashlight) => {
            bgflashlight.onmousemove = function (e) {
                let x = e.pageX - bgflashlight.offsetLeft;
                let y = e.pageY - bgflashlight.offsetTop;

                bgflashlight.style.setProperty("--x", x + "px");
                bgflashlight.style.setProperty("--y", y + "px");
            };
        });
    }, []);
    return (
        <>
            <div className="rainbow-service-area rainbow-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="section-title text-center"
                                data-sal="slide-up"
                                data-sal-duration="700"
                                data-sal-delay="100"
                            >
                                <h4 className="subtitle">
                                    <span className="theme-gradient">APTRON Mission</span>
                                </h4>
                                <h2 className="title w-600 mb--20">
                                    A state-of-the-art AI framework,
                                </h2>
                                <p className="description b1">
                                    APTRON aspires to usher in a future where cutting-edge AI technologies drive decentralization in Web3,
                                    Blockchain, and Crypto, offering accessibility, efficiency,
                                    and user-friendliness to all.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row row--15 service-wrapper">
                        <MissionItem MissionData={MissonData} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Mission;

import React from "react";

import AccordionData from "../../data/home.json";

const AccordionItem = () => {
  return (
    <>
      <div className="rainbow-accordion-style  accordion">
        <div className="accordion" id="accordionExamplea">
          {AccordionData &&
            accordion.map((data, index) => (
              <div className="accordion-item card bg-flashlight" key={index}>
                <h2
                  className="accordion-header card-header"
                  id={`heading${index + 1}`}
                >
                  <button
                    className={`accordion-button ${!data.isExpand ? "collapsed" : ""
                      }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index + 1}`}
                    aria-expanded={data.isExpand ? "true" : "false"}
                    aria-controls={`collapse${index + 1}`}
                  >
                    {data.title}
                  </button>
                </h2>
                <div
                  id={`collapse${index + 1}`}
                  className={`accordion-collapse collapse ${data.isExpand ? "show" : ""
                    }`}
                  aria-labelledby={`heading${index + 1}`}
                  data-bs-parent="#accordionExamplea"
                >
                  <div className="accordion-body card-body">{data.desc}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default AccordionItem;

const accordion = [
  {
    "title": "What is APTRON?",
    "desc": `APTRON represents a state-of-the-art AI framework dedicated to crafting AIboosted solutions for the realms of Web3, Blockchain, and Cryptocurrency. Its
    goal is to enhance accessibility and efficacy within the decentralized digital
    sphere for both users and startups.It achieves this by providing a range of AIdriven tools and applications designed specifically for the dynamic digital
    environment.`,
    "isExpand": true
  },
  {
    "title": "How does APTRON work?",
    "desc": `APTRON stands as a sophisticated AI platform explicitly designed for the
    realms of Web3, Blockchain, and Cryptocurrency. Utilizing cutting-edge AI
    methodologies, it presents a range of tools and applications finely tuned for the
    ever-evolving digital landscape. Users have the flexibility to choose between
    Free, PPP (Pay-Per-Prompt), or Freemium plans, granting access to robust
    features such as smart-contract generation, AI-driven NFT creation, and
    trading assistance. The chosen plan determines whether users enjoy
    unrestricted access, pay-as-they-go, or unlock premium functionalities up to a
    monthly limit. Furthermore, APTRON introduces a token system, denoted by
    $ATN, introducing additional features like staking and exclusive privileges
    based on accumulated staking points. With its comprehensive approach,
    APTRON aims to democratize access to AI-driven tools for individuals,
    startups, and established entities within the decentralized web ecosystem.`,
    "isExpand": false
  },
  {
    "title": "What services can APTRON help with?",
    "desc": <>
      APTRON stands at the intersection of AI and the Web3, Blockchain, and
      Cryptocurrency realms, offering a plethora of AI-powered tools tailored for both
      retail users and enterprises. Here's a snapshot of the services and solutions
      offered by APTRON:
      <ul>
        <li>
          APTRON AI Chatbot
        </li>
        <li>
          APTRON AI on Telegram
        </li>
        <li>
          AI-Generated News
        </li>
        <li>
          P2P Messaging
        </li>
        <li>
          Smart-Contracts Generator
        </li>
        <li>
          Smart-Contracts Auditor
        </li>
        <li>
          AI NFT GeneratorAI Trading Assistant
        </li>
        <li>
          AI Blockchain Analytics
        </li>
        <li>
          Launchpad for AI Startups
        </li>
      </ul>
    </>,
    "isExpand": false
  },
  {
    "title": "What is $ATN token?",
    "desc": <>
      The APTRON Token ($ATN) serves as the utility token fueling the APTRON
      ecosystem. APTRON itself is an advanced AI chatbot tailored to aid
      individuals, developers, and businesses in matters pertaining to Blockchain
      Technology and Cryptocurrencies.
      Here are some primary features and utility cases of the $ATN token:
      <ul>
        <li> Access to APTRON Services: Holders of the token can
          utilize $ATN to gain entry to various services provided by APTRON.
          These include AI-driven news, tools for generating and auditing smart
          contracts, an AI-powered NFT Generator, Launchpad services, and
          more.
        </li>
        <li> Staking and Farming: Users have the option to stake and
          farm their $ATN tokens.
        </li>
        <li> Governance: Token holders of $ATN possess governance
          rights, enabling them to partake in voting processes concerning
          pivotal decisions and proposals linked to the evolution and
          progression of APTRON.
        </li>
        <li> Discounts and Exclusive Features: Owning $ATN tokens
          may offer users discounts on specific services within the APTRON
          ecosystem and grant access to exclusive features.
        </li>
      </ul>
    </>,
    "isExpand": false
  },
  {
    "title": "What blockchain network is $ATN deployed on?",
    "desc": `The $ATN token is a ERC-20 standard token on the Ethereum. As the project
    continues to mature, the token will be bridged over to other EVM-compatible
    chains.`,
    "isExpand": false
  },
  {
    "title": "What is the APTRON DAO and why should I join?",
    "desc": `The APTRON DAO represents a virtual social entity formed by committed
    community members actively involved in safeguarding, governing, and
    advancing APTRON.
    If you're keen on shaping the trajectory of APTRON's progress, engaging in
    voting processes, impacting the allocation of the $ATN treasury fund, and
    becoming part of a close-knit community of AI enthusiasts, the APTRON DAO
    eagerly embraces your participation!`,
    "isExpand": false
  }
]
import React from 'react'
import Image from "next/image";
import Link from "next/link";
import splitImg from "../../public/images/split/split-8.png";

const Teams = () => {
    return (
        <>
            <div className="rainbow-callto-action-area">
                <div className="wrapper">
                    <div className="rainbow-callto-action clltoaction-style-default rainbow-section-gap">
                        <div className="container">
                            <div className="row row--0">
                                <div className="col-lg-12">
                                    <div className="align-items-center content-wrapper">
                                        <div className="inner">
                                            <div className="content text-center">
                                                <h4 className="subtitle ">
                                                    <span className="theme-gradient">Team</span>
                                                </h4>
                                                <h2 className="title w-600 mb--20">
                                                    Always ready our team to help you
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="container">
                                        <div className="genarator-section" >
                                            <div className="row">
                                                {Technlogies.map((data, index) => (
                                                    <div className="col-lg-3" key={index} style={{ height: '100%' }}>
                                                        <div className="genarator-card bg-flashlight-static center-align">
                                                            <div className="inner">
                                                                <div className="left-align">
                                                                    <Image
                                                                        className="radius"
                                                                        src={splitImg}
                                                                        alt="split Images"
                                                                        height={500}
                                                                        width={500}
                                                                    />
                                                                    <br />
                                                                    <h4 className="w-600  mb-2">Pablo Leo</h4>
                                                                    <p className="description b1 color-gray mb--0">{"CEO"}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Teams

const Technlogies = [
    {
        "link": "/",
        "isDisable": true,
        "title": "AI Trading",
        "img": "/images/generator-icon/website-design_line.png",
        "badge": "Coming",
        "wdt": 36
    },
    {
        "link": "/",
        "isDisable": false,
        "title": "AI ",
        "img": "/images/generator-icon/email_line.png",
        "badge": "Coming",
        "wdt": 36
    },
    {
        "link": "/",
        "isDisable": false,
        "title": "AI ",
        "img": "/images/generator-icon/email_line.png",
        "badge": "Coming",
        "wdt": 36
    },
    {
        "link": "/",
        "isDisable": false,
        "title": "AI ",
        "img": "/images/generator-icon/email_line.png",
        "badge": "Coming",
        "wdt": 36
    },
]